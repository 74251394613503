/*Partners Grid Page*/

/* .contain{
    width: calc(100% / 3);
    text-align: left;
    background-color: aliceblue;

} */

/*Start*/
#servicePartdetails{
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 84px);
  padding: 7rem 0 0;

  @media (max-width: 767px) {
      padding: 3rem 0 0;
      
  }

  .servicePart_banner{
      position: absolute;
      overflow: hidden;
      background: url('../../assets/partners/partnerCover.jpg') no-repeat center center;
      opacity: 10%;
      background-size: cover;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      min-height: calc(100vh - 84px);
      opacity: 50%;
      transform: scale(1.1);
      animation: wave 20s linear infinite;

      &:before{
          content: '';
          position: absolute;
          top: 0;
          min-height: 100%;
          left: 0;
          right: 0;
          z-index: 2;
          background: var(--dark-layer-color);
      }

  }

  .servicePart_outer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;

    @media (max-width:992px) {
        flex-direction: column-reverse;
    }

    

    .servicePart_title{
        width:100%;
        padding: 80px;
        font-size: 4rem;
        line-height: 5rem;
        font-weight: 200;
        margin-bottom: 1rem;
        text-transform: uppercase;
        text-align: center;
        justify-content: center;

        & span{
            font-weight: 800;
        }
        @media (max-width:2000px) {
            padding-top: 250px;
        }
        @media (max-width:1500px) {
            padding-top: 120px;
        } 
        @media (max-width:992px) {
            
            width: 100%;
            margin-top: 10rem;
            text-align: center;
            line-height: 4rem;
            
        }
        @media (max-width:767px) {
            
            width: 100%;
            font-size: 2.5rem;
            
        }
    }
}

}







/*End*/

.partner_down_arrow{
    text-align: center;
    z-index: 999;
    margin-top: 10px;
    width: 5px;
    margin-left: calc(100%/2);
    position: relative;
    height: 5px;
    
}



.rows {
    background-color: white;
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;

  
  
}


/* Create two equal columns that sits next to each other */
.column {
  flex: 50%;
  padding: 40 40px;
  margin-top: 50px;
  margin-left: 20px;

  p{
    text-align: center;
    color: #171717;
    padding-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
}

.column img {
  margin-top: 10px;
  margin-left: 30px;
  padding-left: 30px;
  vertical-align: middle;
  padding-bottom: 20px;

  @media (max-width: 767px) {
    padding-left: 20px;
    
}
  
}