/*Blog List CSS*/

.blogList_wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 3rem;
   
}

@media(max-width:1100px){
    .blogList_wrapper{
        grid-template-columns: repeat(2,1fr);
    }
}
@media(max-width:900px){
    .blogList_wrapper{
        grid-template-columns: repeat(2,1fr);
    }
}
@media(max-width:800px){
    .blogList_wrapper{
        grid-template-columns: repeat(2,1fr);
    }
}

@media(max-width:600px){
    .blogList_wrapper{
        grid-template-columns: repeat(1,1fr);
    }
}