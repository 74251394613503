/* #servicedetailsabout{
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 84px);
    padding: 8rem 0 0;

    @media (max-width: 767px) {
        padding: 3rem 0 0;
        
    }


    .about_details{
        width: calc(100% / 2);


        padding: 1px;
        display: grid;
        margin-top: 60px;

        p{
            font-size: 16px;

            
            
        }
    }

    

    hr{
        margin-top: 30px;
        opacity: 50%;
    }



    img{
        max-width: 30%;
        max-height:30%;
        float: right;
        padding: 10px;
        border-radius: 30px;
        
        margin-right: 20px;

        transform: scale(1.1);
        animation: wave 10s linear infinite;

        &:before{
            content: '';
            position: absolute;
            top: 0;
            min-height: 100%;
            left: 0;
            right: 0;
            z-index: 2;
            background: var(--dark-layer-color);
        }

        @media (max-width: 992px) {
            width: calc(100% / 2);
            
        }
        

    }
} */


/*Blog CSS*/

#about_container{
    padding: 6rem 0 4rem;

    .about_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 992px) {
            flex-direction: column-reverse;
            

        }

        
        .about_col{
            width: calc(100% / 2 -15px);
            height: auto;
            position: relative;
            z-index: 1;

            .amm_title_para{
                padding-top: 50px;
                padding-bottom: 50px;
                text-align: left;
                font-size: 20px;

                

                @media (max-width: 1024px) {
                    width: 100%;
                   
                    text-align: center;
                    
                }

                @media (max-width: 992px) {
                    width: 100%;
                    text-align: center;
                    
                }
            }

            @media (max-width: 992px) {
                width: fit-content;
                padding: 2rem;
                
            }

            @media (max-width: 767px) {
                padding: 1.5rem;
                
            }
        }

        & h3{
            width: 80%;
            font-size: 3rem;
            line-height: 3.714rem;
            font-weight: 600;
            text-transform: uppercase;

            @media (max-width: 992px) {
                width: 100%;
                text-align: center;
                
            }

            @media (max-width: 767px) {
                width: 100%;
                font-size: 2.2rem;
                line-height: 3.5rem;
                
            }
        }

        & p{
            /* width: 80%; */
            width: 100%;
            margin: 0;
            padding: 0;
            font-size: 1.143rem;
            line-height: 2rem;
            /* color: var(--font-color); */
            color: #ffffff;
            list-style-position: inside;

            text-align: left;

            @media (max-width: 1800px) {
                width: 100%;
                
            }
            @media (max-width: 992px) {
                width: 100%;
                text-align: center;
                
            }
        }

        .about_img{
            position: relative;
            text-align: right;
            margin-right: 20px;
       
         

            &::after{
                content: '';
                position: absolute;
                
                right: 45px;
                left: -45px;
                bottom: 45px;
                top: -45px;
                z-index: -1;
                border-radius: 0 2.1429rem;
            }

            @media (max-width: 767px) {
                &::after{
                    left: 0;
                }
                
            }

            & img{
                border-radius: 0 2.1429rem;
                max-width: 50%;

                @media (max-width: 1100px) {
                    display: none;
                    
                }
                @media (max-width: 767px) {
                    max-width: 85%;
                    
                }
            }
        }

        .amm_get_started{
            
                
            padding-bottom: 40px;
        padding-top: 60px;
    margin: auto;
    text-align: left;

    @media (max-width: 992px) {
        justify-content: center;
            text-align: center;
            margin: auto;
        
    }

        
    }
    }
}


.app_timeline{
    position: relative;
    max-width: 1200px;
    margin: 100px auto;
}

.app_timeline_container{
    padding: 10px 50px;
    position: relative;
    width: 50%;
    animation: movedown 1s linear forwards;
    opacity: 0;
    z-index: 10;
}

@keyframes movedown{
    0%{
        opacity: 1;
        transform: translateY(-30px);
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }

}

.app_timeline_container:nth-child(1){
    animation-delay: 0s;
}

.app_timeline_container:nth-child(2){
    animation-delay: 0.5s;
}

.app_timeline_container:nth-child(3){
    animation-delay: 1s;
}

.app_timeline_container:nth-child(4){
    animation-delay: 1.5s;
}

.app_timeline_container:nth-child(5){
    animation-delay: 2s;
}

.app_timeline_container:nth-child(6){
    animation-delay: 2.5s;
}






.app_text_box{
    padding: 20px 30px;
    background: #ffffff;
    position: relative;
    border-radius: 6px;
    font-size: 15px;

}

.app_text_box h3{
    color: black;
    text-align: left;
    font-weight: 600;

    @media (max-width:400px) {
        font-size: 20px;
    } 
}

.app_text_box small{
    font-size: 13px;
    display: inline-block;
    margin-bottom: 15px;
}

.app_text_box p{
    color: black;
    text-align: left;
    justify-content: left;
}

.app_left_container{
    left: 0;
}

.app_right_container{
    left: 50%;
}

.app_timeline_container img{
    position: absolute;
    width: 40px;
    border-radius: 50%;
    right: -20px;
    top: 32px;
    z-index: 10;
}

.app_right_container img{
    left: -20px;
}

.app_timeline::after{
    content: '';
    position: absolute;
    width: 6px;
    height: 100%;
    background: #ffffff;
    top: 0;
    left: 50%;
    margin-left: -3px;
    animation: moveline 6s linear forwards;
    /* z-index: -1; */
}

@keyframes moveline{
    0%{
        height: 0;
    }
    100%{
        height: 100%;
    }
}

.app_left_container_arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #ffffff;
    right: -15px;
}

.app_right_container_arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #ffffff;
    left: -15px;
}


@media screen and (max-width: 2000px){
    .app_timeline{
        margin: 50px auto;
    }
    .app_timeline::after{
        left: 31px;
    }
        
    .app_timeline_container{
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;

    }

    .app_text_box{
        font-size: 13px;
    }

    .app_text_box small{
        margin-bottom: 10px;
    }

    .app_right_container{
        left: 0;
    }

    
    .app_left_container img, .app_right_container img{
        left: 10px;
    }

    .app_left_container_arrow, .app_right_container_arrow{
        border-right: 15px solid #ffffff;
        border-left: 0;
        left: -15px;
    }
    
}

.app_para_headleft{
    text-align: left;
 
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width:400px) {
        text-align: center;
        justify-content: center;
        left: 0;
        right: 0;
        font-size: 22px;
        padding-left: 0%;
        padding-right: 0%;

    } 

    @media (max-width:500px) {
    
        left: 0;
        right: 0;
        padding-left: 0%;
        padding-right: 0%;
      
    } 
}
.app_para_left{
    text-align: left;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width:400px) {
        text-align: center;
        justify-content: center;
        left: 0;
        right: 0;
        font-size: 10px;
        line-height: 2;
        padding-left: 0%;
        padding-right: 0%;
    } 
    @media (max-width:500px) {
    
        left: 0;
        right: 0;
        padding-left: 0%;
        padding-right: 0%;
      
    } 
}

.app_container{
    text-align: left;
    width: auto;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 5rem;
  padding-left: 5rem;
}