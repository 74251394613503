/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/*Poppins Font*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,800;0,900;1,200&display=swap');

/*Global Variables*/
:root{
  /*Background Color*/
  /* --primary-color: #393678; */
  --primary-color: #990033;
  /* --secondary-color: #ffc2b4; */
  --secondary-color: #292727;
  --primary-nav-color: #e21056;
  --primary-bg: #99003324;
  --font-color: #b7b7b7;
  /* --bg-color: #010c1e; */
  --bg-color: #171717;
  --bg-dark-color: #0c0b0b;
  --white-color: #fdfffc;
  --green-color: #0abf53;
  --green-color-bg: rgb(10 191 83/17%);
  --blue-color: #6168ff;
  --blue-color-bg: rgb(97 104 255/9%);
  --border-color-light: rgb(255,255,255,0.2);
  --box-shadow: 0 1rem 3rem rgb(0 0 0/12%);
  --dark-layer-color: linear-gradient(to top, rgb(21 21 21/67%), rgb(23 23 23/17%));
  /*Font Family*/
  --primary-font: 'Poppins', sans-serif;



  

}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  

  
}

html,body{
  font-size: 14px;
  font-family: var(--primary-font);
  background-color: var(--bg-color);
}

/*Custom Scrollbar*/
::-webkit-scrollbar{
  width: .375rem;

  
}

::-webkit-scrollbar-track{
  background: var(--secondary-color);
}

::-webkit-scrollbar-thumb{
  background: var(--primary-color);
}

/*Pre Defined Classes CSS*/
a{
  text-decoration: none;
}

.container-fluid{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container{
  width: auto;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 5rem;
  padding-left: 5rem;


 

  
}


.btn_wrapper{
  margin-top: 3rem;

  @media (max-width: 1024px){
    width: 100%;
    text-align: center;
    display: block;
  }

  @media (max-width: 500px){
    margin-top: 20px;
    display: flex;
    flex: auto;
    text-align: center;
    margin-left: 45px;

    
  }

@media (max-width: 400px){
    margin-top: 20px;
    display: flex;
    flex: auto;
    margin-left: 20px;
    text-align: center;

    
  }
  @media (max-width: 320px){
    margin-top: 20px;
    display: flex;
    flex: auto;
    margin-left: 20px;
    text-align: center;
    
  }

  @media (max-width: 767px){
    margin-bottom: 3rem;
    
  }

  .btn{
    padding: 0.857rem 2.571rem;
    font-size: 1.143rem;
    border-radius: 2.143rem;
    font-weight: 600;
    /* box-shadow: 0 0.5rem 1rem rgb(16,16,16); */
    outline: 2px solid var(--primary-color);
    outline-offset: 0px;
    border: none;
    cursor: pointer;
    color: var(--white-color);
    background: var(--primary-color);
    transition: all .3s ease-in-out;

    

    &:hover{
      outline-offset: 0.7143rem;
      
    }
  }
}

h1{
  font-size: 3.875rem;
  margin-bottom: 1.25rem;
  font-weight: 600;
  color: var(--white-color);
}

h2{
  font-size: 2.875rem;
  font-weight: 600;
  color: var(--white-color);
}

h3{
  font-size: 2.25rem;
  font-weight: 600;
  /* margin-left: 25px; */
  color: var(--white-color);
}

h4{
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 600;
  color: var(--white-color);
}

h5{
  font-size: 1.5rem;
  line-height: 1.2rem;
  margin-bottom: .9375rem;
  font-weight: 500;
  color: var(--white-color);
}

h6{
  font-size: .875rem;
  margin-bottom: .9375rem;
  text-transform: uppercase;
  font-weight: 300;
  color: var(--white-color);
}

p{
  font-size: 1rem;
  color: var(--font-color);
  line-height: 1.625rem;
}

.wa_iconss{
  position: fixed;
    right: 1px;
    bottom: 1px;
    z-index: 99;
    cursor: pointer;
    width: 2.8571rem;
    height: 2.8571rem;
    bottom: 30px;
    right: 38px;
    text-align: right;
    border-radius: 100%;
    

    & img:hover{
      outline-offset: 0.7143rem;
      color: #990033;
      opacity: 100%;

      
    }

    & img{
        text-align: center;
        width: 55px;
        height: 40px;
        border-radius: 50%;
        opacity: 50%;

        
        
      
    }
    
}