/*BLog Grids*/

.home-header{
    padding-top: 60px;
    text-align: center;
    
}

.home-header h2{
    
    color: #0080ff;
    font-size: 32px;
}

.home-header h1{
    
    color: #0f52ba;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.home-header h1 span{
    
    color: #b0c4de;
}

.home-header p{
    color: #a9a9a9;
    font-weight: 500;
}

