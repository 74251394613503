/*Blog Chip CSS*/

.chip{
    font-size: 1rem;
    /* background: linear-gradient(to right, #6190e8, #a7bfe8); */
   
    color: #ffffff;
    
    padding: .3rem .5rem;
    border-radius: 5px;
    width: fit-content;
    text-transform: capitalize;
        border-color: #990033;
    border-bottom: 3px solid #990033;
    border-top: transparent;
    border-left: transparent;
   
    
}