/*Service Details CSS*/

#serviceAppdetails{
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 84px);
    padding: 8rem 0 0;

    @media (max-width: 767px) {
        padding: 3rem 0 0;
        
    }

    .serviceapp_banner{
        position: absolute;
        overflow: hidden;
        background: url('../../assets/banner/sd04.jpg') no-repeat center center;
        background-size: cover;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 84px);
        opacity: 50%;
        transform: scale(1.1);
        animation: wave 20s linear infinite;

        &:before{
            content: '';
            position: absolute;
            top: 0;
            min-height: 100%;
            left: 0;
            right: 0;
            z-index: 2;
            background: var(--dark-layer-color);
        }

    }


    .serviceapp_outer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 3;

        @media (max-width:992px) {
            flex-direction: column-reverse;
        }


        

        .serviceapp_title{
            width:100%;
            padding: 80px;
            /* padding-top: 250px; */
            font-size: 4rem;
            line-height: 5rem;
            font-weight: 200;
            margin-bottom: 1rem;
            text-transform: uppercase;
            text-align: center;

            & span{
                font-weight: 800;
            }
            @media (max-width:2000px) {
                padding-top: 250px;
            }
            @media (max-width:1500px) {
                padding-top: 120px;
            } 
            @media (max-width:992px) {
                
                width: 100%;
                margin-top: 10rem;
                text-align: center;
                line-height: 4rem;
             
                
            }
            @media (max-width:767px) {
                
                width: 100%;
                font-size: 2.5rem;
                
                
            }
            @media (max-width:700px) {
                
                width: 100%;
                font-size: 2.5rem;
                display: flex;
                justify-content: center;
                
            }

            @media (max-width: 600px){
              
             
                font-size: 2.5rem;
                display: flex;
                justify-content: center;
            
        
                
              }
              @media (max-width: 550px){
              
               
                font-size: 2.5rem;
                display: flex;
                justify-content: center;
            
                
              }

            @media (max-width: 500px){
            
            
                font-size: 1.8rem;
                display: flex;
                justify-content: center;
             
        
                
              }
        
            @media (max-width: 450px){
                text-align: center;
         
                font-size: 1.8rem;
                align-items: center;
                display: flex;
                justify-content: center;
                
              
                
        
                
              }
              @media (max-width: 400px){
                text-align: center;
          
                font-size: 1.5rem;
                align-items: center;
                display: fixed;

               
             
        
                
              }
              @media (max-width: 320px){
                text-align: center;
            
                font-size: 1.5rem;
                align-items: center;
                line-height: 3.5rem;
                display: fixed;
              
                
              }
        }
    }

}

.app_down_arrow{
    text-align: center;
    z-index: 999;
    margin-top: 10px;
    width: 5px;
    margin-left: calc(100%/2);
    position: relative;
    height: 5px;
    
}

hr{
    opacity: 30%;
    margin-top: 20px;

}


