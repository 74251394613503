/*Service Audio Visual*/

#serviceSAVVdetails{
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 84px);
    padding: 8rem 0 0;

    @media (max-width: 767px) {
        padding: 3rem 0 0;
        
    }

    .serviceSAVV_banner{
        position: absolute;
        overflow: hidden;
        background: url('../../assets/service_about/savCover.jpg') no-repeat center center;
        opacity: 10%;
        background-size: cover;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 84px);
        opacity: 50%;
        transform: scale(1.1);
        animation: wave 20s linear infinite;

        &:before{
            content: '';
            position: absolute;
            top: 0;
            min-height: 100%;
            left: 0;
            right: 0;
            z-index: 2;
            background: var(--dark-layer-color);
        }

    }


    .serviceSAVV_outer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 3;

        @media (max-width:992px) {
            flex-direction: column-reverse;
        }



        

        .serviceSAVV_title{
            width:100%;
            padding: 80px;
            /* padding-top: 250px; */
            font-size: 4rem;
            line-height: 5rem;
            font-weight: 200;
            margin-bottom: 1rem;
            text-transform: uppercase;
            text-align: center;

            & span{
                font-weight: 800;
            }
            @media (max-width:2000px) {
                padding-top: 250px;
            }
            @media (max-width:1500px) {
                padding-top: 120px;
            } 
            @media (max-width:992px) {
                
                width: 100%;
                margin-top: 10rem;
                text-align: center;
                line-height: 4rem;
             
                
            }
            @media (max-width:767px) {
                
                width: 100%;
                font-size: 2.5rem;
                
                
            }
            @media (max-width:700px) {
                
                width: 100%;
                font-size: 2.5rem;
                display: flex;
                justify-content: center;
                
            }

            @media (max-width: 600px){
              
             
                font-size: 2.5rem;
                display: flex;
                justify-content: center;
            
        
                
              }
              @media (max-width: 550px){
              
               
                font-size: 2.5rem;
                display: flex;
                justify-content: center;
            
                
              }

            @media (max-width: 500px){
            
            
                font-size: 1.8rem;
                display: flex;
                justify-content: center;
             
        
                
              }
        
            @media (max-width: 450px){
                text-align: center;
         
                font-size: 1.8rem;
                align-items: center;
                display: flex;
                justify-content: center;
                
              
                
        
                
              }
              @media (max-width: 400px){
                text-align: center;
          
                font-size: 1.5rem;
                align-items: center;
                display: fixed;

               
             
        
                
              }
              @media (max-width: 320px){
                text-align: center;
            
                font-size: 1.5rem;
                align-items: center;
                line-height: 3.5rem;
                display: fixed;
              
                
              }
        }
    }

}

.sav_down_arrow{
    text-align: center;
    z-index: 999;
    margin-top: 10px;
    width: 5px;
    margin-left: calc(100%/2);
    position: relative;
    height: 5px;
    
}

hr{
    opacity: 30%;
    margin-top: 20px;

}


#serviceSAV_container{
    padding: 6rem 0 4rem;

    .serviceSAV_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 992px) {
            flex-direction: column-reverse;
            
        }

        .serviceSAV_col{
            width: calc(100% / 2 -25px);
            height: auto;
            position: relative;
            z-index: 1;

            .ims_title_para{
                padding-top: 50px;
                padding-bottom: 50px;
                text-align: left;
                font-size: 20px;

                

                @media (max-width: 1024px) {
                    width: 100%;
                   
                    text-align: center;
                    
                }

                @media (max-width: 992px) {
                    width: 100%;
                    text-align: center;
                    
                }
            }


            @media (max-width: 992px) {
                width: fit-content;
                padding: 2rem;
                
            }

            @media (max-width: 767px) {
                padding: 1.5rem;
                
            }
        }

        & h3{
            width: 50%;
            font-size: 3rem;
            line-height: 3.714rem;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 30px;

            @media (max-width: 992px) {
                width: 100%;
                text-align: center;
                
            }

            @media (max-width: 767px) {
                width: 100%;
                font-size: 2.2rem;
                line-height: 3.5rem;
                
            }
        }


        & p{
            /* width: 65%; */
            width: 100%;
            margin-top: 10p;
            margin: 0;
            padding: 0;
            font-size: 1.143rem;
            line-height: 2rem;
            color: #ffffff;
            text-align: left;
            list-style-position: inside;

            @media (max-width: 1800px) {
                width: 100%;
                
            }
            @media (max-width: 992px) {
                width: 100%;
                text-align: center;
                
            }
        }

        .serviceSAV_img{
            position: relative;
            text-align: right;
            margin-right: 20px;

            &::after{
                content: '';
                position: absolute;
                
                right: 45px;
                left: -45px;
                bottom: 45px;
                top: -45px;
                z-index: -1;
                border-radius: 0 2.1429rem;
            }

            @media (max-width: 767px) {
                &::after{
                    left: 0;
                }
                
            }

            & img{
                border-radius: 0 2.1429rem;
                max-width: 50%;

                @media (max-width: 1100px) {
                    display: none;
                    
                }
                @media (max-width: 767px) {
                    max-width: 85%;
                    
                }
            }
        }
        .ims_get_started{
            
                
            padding-bottom: 40px;
        padding-top: 60px;
    margin: auto;
    text-align: left;

    @media (max-width: 992px) {
        justify-content: center;
            text-align: center;
            margin: auto;
        
    }

        
    }
    }
}


.sav_timeline{
    position: relative;
    max-width: 1200px;
    margin: 100px auto;
}

.sav_timeline_container{
    padding: 10px 50px;
    position: relative;
    width: 50%;
    animation: movedown 1s linear forwards;
    opacity: 0;
    z-index: 10;
}

@keyframes movedown{
    0%{
        opacity: 1;
        transform: translateY(-30px);
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }

}

.sav_timeline_container:nth-child(1){
    animation-delay: 0s;
}

.sav_timeline_container:nth-child(2){
    animation-delay: 0.5s;
}

.sav_timeline_container:nth-child(3){
    animation-delay: 1s;
}

.sav_timeline_container:nth-child(4){
    animation-delay: 1.5s;
}

.sav_timeline_container:nth-child(5){
    animation-delay: 2s;
}

.sav_timeline_container:nth-child(6){
    animation-delay: 2.5s;
}

.sav_timeline_container:nth-child(7){
    animation-delay: 3s;
}

.sav_timeline_container:nth-child(8){
    animation-delay: 3.5s;
}

.sav_timeline_container:nth-child(9){
    animation-delay: 4s;
}

.sav_timeline_container:nth-child(10){
    animation-delay: 4.5s;
}





.sav_text_box{
    padding: 20px 30px;
    background: #ffffff;
    position: relative;
    border-radius: 6px;
    font-size: 15px;

}

.sav_text_box h3{
    color: black;
    text-align: left;
    font-weight: 600;

    @media (max-width:400px) {
        font-size: 20px;
    } 
}

.sav_text_box small{
    font-size: 13px;
    display: inline-block;
    margin-bottom: 15px;
}

.sav_text_box p{
    color: black;
    text-align: left;
    justify-content: left;
}

.sav_left_container{
    left: 0;
}

.sav_right_container{
    left: 50%;
}

.sav_timeline_container img{
    position: absolute;
    width: 40px;
    border-radius: 50%;
    right: -20px;
    top: 32px;
    z-index: 10;
}

.sav_right_container img{
    left: -20px;
}

.sav_timeline::after{
    content: '';
    position: absolute;
    width: 6px;
    height: 100%;
    background: #ffffff;
    top: 0;
    left: 50%;
    margin-left: -3px;
    animation: moveline 6s linear forwards;
    /* z-index: -1; */
}

@keyframes moveline{
    0%{
        height: 0;
    }
    100%{
        height: 100%;
    }
}

.sav_left_container_arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #ffffff;
    right: -15px;
}

.sav_right_container_arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #ffffff;
    left: -15px;
}


@media screen and (max-width: 2000px){
    .sav_timeline{
        margin: 50px auto;
    }
    .sav_timeline::after{
        left: 31px;
    }
        
    .sav_timeline_container{
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;

    }

    .sav_text_box{
        font-size: 13px;
    }

    .sav_text_box small{
        margin-bottom: 10px;
    }

    .sav_right_container{
        left: 0;
    }

    
    .sav_left_container img, .sav_right_container img{
        left: 10px;
    }

    .sav_left_container_arrow, .sav_right_container_arrow{
        border-right: 15px solid #ffffff;
        border-left: 0;
        left: -15px;
    }
    
}

.sav_para_headleft{
    text-align: left;
 
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width:400px) {
        text-align: center;
        justify-content: center;
        left: 0;
        right: 0;
        font-size: 22px;
        padding-left: 0%;
        padding-right: 0%;

    } 

    @media (max-width:500px) {
    
        left: 0;
        right: 0;
        padding-left: 0%;
        padding-right: 0%;
      
    } 
}
.sav_para_left{
    text-align: left;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width:400px) {
        text-align: center;
        justify-content: center;
        left: 0;
        right: 0;
        font-size: 10px;
        line-height: 2;
        padding-left: 0%;
        padding-right: 0%;
    } 
    @media (max-width:500px) {
    
        left: 0;
        right: 0;
        padding-left: 0%;
        padding-right: 0%;
      
    } 
}

.sav_container{
    text-align: left;
    width: auto;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 5rem;
  padding-left: 5rem;
}