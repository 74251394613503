/*AMC Service CSS*/

#serviceAMCdetails{
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 84px);
    padding: 8rem 0 0;



    @media (max-width: 767px) {
        padding: 3rem 0 0;
        
    }
    

    .serviceAMC_banner{
        position: absolute;
        overflow: hidden;
        background: url('../../assets/service_about/amc_cover.jpg') no-repeat center center;
        opacity: 10%;
        background-size: cover;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 84px);
        opacity: 50%;
        transform: scale(1.1);
        animation: wave 20s linear infinite;

        
        &:before{
            content: '';
            position: absolute;
            top: 0;
            min-height: 100%;
            left: 0;
            right: 0;
            z-index: 2;
            background: var(--dark-layer-color);
        }

    }


    .serviceAMC_outer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 3;

        @media (max-width:992px) {
            flex-direction: column-reverse;
        }

        


        

        .serviceAM_title{
            width:100%;
            padding: 80px;
            font-size: 4rem;
            line-height: 5rem;
            font-weight: 200;
            margin-bottom: 1rem;
            text-transform: uppercase;
            text-align: center;
            justify-content: center;

            & span{
                font-weight: 800;
            }
            @media (max-width:2000px) {
                padding-top: 250px;
            }
            @media (max-width:1500px) {
                padding-top: 120px;
            } 
            @media (max-width:992px) {
                
                width: 100%;
                margin-top: 10rem;
                text-align: center;
                line-height: 4rem;
                
            }
            @media (max-width:767px) {
                
                width: 100%;
                font-size: 2.5rem;
                text-align: center;
                
            }
            @media (max-width:700px) {
                
                width: 100%;
                font-size: 2.5rem;
                display: flex;
                justify-content: center;

            }
            @media (max-width:600px) {
                
                width: 100%;
                font-size: 2.5rem;
                display: flex;
                justify-content: center;

            }
            @media (max-width:500px) {
                
                width: 100%;
                font-size: 2.5rem;
                display: flex;
                justify-content: center;

            }
            @media (max-width:400px) {
                
                width: 100%;
                font-size: 2.5rem;
                display: flex;
                justify-content: center;

            }
            @media (max-width:300px) {
                
                width: 100%;
                font-size: 2.5rem;
                display: flex;
                justify-content: center;

            }
            @media (max-width:200px) {
                
                width: 100%;
                font-size: 2.5rem;
                display: flex;
                justify-content: center;

            }
            @media (max-width:100px) {
                
                width: 100%;
                font-size: 2.5rem;
                display: flex;
                justify-content: center;

            }

        }

        
    }


}

p{
    width: 100%;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.143rem;
    line-height: 2rem;
    color: #ffffff;
    text-align: center;
    justify-content: center;

    @media (max-width: 992px) {
        width: 100%;
        text-align: center;
        
    }
    @media (max-width: 400px) {
        width: 100%;
        text-align: center;
        font-size: 12px;
        
    }
}

.down_arroww{
    text-align: center;
    z-index: 999;
    margin: auto;
    align-items: center;
    margin-top: 10px;
    width: 5px;
    height: 5px;
    
}



#serviceAMC_maincontainer{
    padding: 3rem 0 4rem;

    @media (max-width:1024px) {
        overflow: hidden;
        
    }

    .aboutDetails_mainwrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 992px) {
            flex-direction: column-reverse;
            
        }

        .aboutDetails_maincol{
            width: calc(100% / 2 -25px);
            height: auto;
            position: relative;
            z-index: 1;


            @media (max-width: 992px) {
                width: fit-content;
                padding: 2rem;
                
            }

            @media (max-width: 767px) {
                padding: 1.5rem;
                
            }
        }

        & h3{
            width: 100%;
            font-size: 3rem;
            line-height: 3.714rem;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 30px;

            @media (max-width: 992px) {
                width: 100%;
                text-align: center;
                
            }

            @media (max-width: 767px) {
                width: 100%;
                font-size: 2.2rem;
                line-height: 3.5rem;
                
            }
        }


        & p{
            width: 100%;
            margin-top: 10px;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 1.143rem;
            line-height: 2rem;
            color: #ffffff;
            text-align: center;
            justify-content: center;

            @media (max-width: 992px) {
                width: 100%;
                text-align: center;
                
            }

        }

        .serviceAC_img{
            position: relative;
            text-align: right;
            margin-right: 20px;

            &::after{
                content: '';
                position: absolute;
                
                right: 45px;
                left: -45px;
                bottom: 45px;
                top: -45px;
                z-index: -1;
                border-radius: 0 2.1429rem;
            }

            @media (max-width: 767px) {
                &::after{
                    left: 0;
                }
                
            }

            & img{
                border-radius: 0 2.1429rem;
                max-width: 50%;

                @media (max-width: 767px) {
                    max-width: 85%;
                    
                }
            }
        }
    }
}




.serviceAMC_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;

    @media (max-width:767px) {
        flex-direction: column;
        
    }
   
   

    .serviceAMC_box{
        width: calc(100% / 3 - 2rem);
        padding: 2.8571rem 2.1429rem;
        border-radius: 1.4286rem;
        background: var(--bg-dark-color);
        box-sizing: border-box;
        margin-bottom: 2rem;
        position: relative;
        z-index: 3;
        height: 430px;

        @media (max-width:1100px) {
            width: calc(100% / 2 - 1rem);
            
        }

        @media (max-width:767px) {
            width: 100%;
            height: 350px;
            
        }
        @media (max-width:800px) {
            width: 100%;
            
        }
        @media (max-width:900px) {
            width: 100%;
            
        }
        @media (max-width:1000px) {
            width: 100%;
            
        }
        @media (max-width:1100px) {
            width: calc(50% - 1rem);

            
        }
      

        @media (max-width:400px) {
            height: 550px;
            
        }
        @media (max-width:450px) {
            height: 550px;
            
        }
        @media (max-width:449px) {
            height: 400px;
            
        }
        @media (max-width:449px) {
            width: 200px;
            height: 300px;
            
        }
        

        &.last_one{
            width: calc(100% / 2 - 2rem);
        padding: 2.8571rem 2.1429rem;
        border-radius: 1.4286rem;
        align-items: center;
        margin: auto;
        background: var(--bg-dark-color);
        box-sizing: border-box;
        margin-bottom: 2rem;
        position: relative;
        z-index: 3;
        height: 500px;


        @media (max-width:1100px) {
            width: calc(100% / 2 - 1rem);
            
        }
        
        @media (max-width:450px) {
            height: 550px;
            
        }
        @media (max-width:400px) {
            height: 500px;
            
        }
        @media (max-width:600px) {
            width: 100%;
            
        }

        @media (max-width:767px) {
            width: 100%;
            
        }
        @media (max-width:800px) {
            width: 100%;
            
        }
        @media (max-width:900px) {
            width: 100%;
            
        }
        @media (max-width:1000px) {
            width: 100%;
            
        }
        @media (max-width:1100px) {
            width: 100%;
            
        }
        }


            

            /* &:hover{
         
          
            --initialBgColor: var(--bg-dark-color);
            --slideBgColor: #292828;
          
          
            background-image: linear-gradient(90deg,
                var(--initialBgColor) 0%,
                var(--initialBgColor) 50%,
                var(--slideBgColor) 50%,
                var(--slideBgColor) 100%);
            background-size: 200%;
            transition: background-position .3s cubic-bezier(.47, .1, 1, .63),
              color .2s linear;
            transition-delay: 0.0s, 0.15s;
                background-color:var(--initialTextColor);
                background-position: -100% 100%;
            
            } */

        

        .serviceAMC_Lines{
            position: absolute;
            font-size: 40px;
            line-height: 4.2857rem;
            right: 2.8571rem;
            top: 2.1429rem;
            opacity: .07;
            margin: 0;
            transition: 0.3s ease-in-out;

            @media (max-width:350px) {
                display: none;
                
            }
            @media (max-width:767px) {
                display: none;
                
            }

            
        }

        .serviceAMC_icon{
            font-size: 2.2857rem;
            width: 5rem;
            height: 5rem;
            line-height: 5rem;
            border-radius: 50%;
            color: var(--primary-color);
            background: var(--primary-bg);
            text-align: center;
            margin-left: 110px;
            margin-bottom: 20px;
            @media (max-width:350px) {
                display: none;
                
            }
            @media (max-width:767px) {
                display: none;
                
            }

            &.green_icon{
                color: var(--green-color);
                background: var(--green-color-bg);
            }

            &.blue_icon{
                color: var(--blue-color);
                background: var(--blue-color-bg);
            }
        }

        & h5{
            font-weight: 600;
            color: var(--white-color);
            font-size: 1.7143rem;
            line-height: 2.4286rem;
            text-align: center;

            @media (max-width:500px) {
                font-size: 17px;
            
                
            }
            @media (max-width:750px) {
                font-size: 17px;
            
                
            }
            @media (max-width:580px) {
                font-size: 15px;
                line-height: 20px;
            
                
            }
            @media (max-width:540px) {
                font-size: 14px;
                line-height: 20px;
                justify-content: center;
                text-align: center;
                margin: auto;
            
                
            }
            @media (max-width:480px) {
                font-size: 13px;
                line-height: 20px;
                text-align: center;
        margin: auto;
        justify-content: center;
            
                
            }
            /* @media (max-width:350px) {
                line-height: 200%;
                font-size: 12px;
                text-align: center;
             
           
            } */
        }

        & p{
            font-size: 1.2143rem;
            line-height: 2rem;
            color: var(--font-color);
            font-weight: 500;

            @media (max-width:820px) {
                font-size: 10px;
                line-height: 180%;
            }
            @media (max-width:823px) {
                font-size: 14px;
                line-height: 180%;
            }
            
            @media (max-width:600px) {
                font-size: 13px;
                line-height: 180%;
            }
            @media (max-width:750px) {
                font-size: 12px;
      
                
            }
            @media (max-width:620px) {
                font-size: 11px;
      
                
            }
            @media (max-width:580px) {
                font-size: 11px;
                padding-left: 1px;
                padding-right: 1px;
      
                
            }
            @media (max-width:540px) {
                font-size: 11px;
                line-height: 14px;
                padding-left: 1px;
                padding-right: 1px;
                justify-content: center;
                text-align: center;
                
      
                
            }
            /* @media (max-width:550px) {
                font-size: 13px;
                line-height: 130%;
                
            }
            @media (max-width:500px) {
                font-size: 13px;
                line-height: 130%;
                
            }
            @media (max-width:650px) {
                font-size: 16px;
            }
            @media (max-width:450px) {
                line-height: 180%;
                font-size: 12.5px;
               
            }
            @media (max-width:400px) {
                line-height: 200%;
                font-size: 10px;
           
            }
            @media (max-width:350px) {
                line-height: 100%;
                font-size: 9px;
                text-align: center;
           
            }
            @media (max-width:300px) {
                line-height: 100%;
                font-size: 10px;
           
            } */

        }

        .read{
            font-size: 1rem;
            font-weight: 600;
            color: var(--white-color);
            margin-top: 2.1429rem;
            display: inline;
            text-transform: capitalize;
        }
    }
    .btn_wrapper{
        margin-top: 2rem;
        z-index: 999;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}