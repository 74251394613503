/*Home CSS*/

#home{
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 84px);
    padding: 8rem 0 0;

    @media (max-width: 767px) {
        padding: 3rem 0 0;
        
    }

    

    .banner_image{
        position: absolute;
        overflow: hidden;
        background: url('../../assets/banner/bannerfinal2.jpg') no-repeat center center;
        
        /* background: url('../../assets/banner/banner05.jpg') no-repeat center center; */
        background-size: cover;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 84px);
        opacity: 30%;
        transform: scale(1.1);
        animation: wave 5s linear infinite;

        


        &:before{
            content: '';
            position: absolute;
            top: 0;
            min-height: 100%;
            left: 0;
            right: 0;
            z-index: 2;
            background: var(--dark-layer-color);

            
        }

        

    }

    .banner_outer{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 3;

        @media (max-width:992px) {
            flex-direction: column-reverse;
            
        }

        .col{
           
            /* width: calc(100% / 2 - 15px); */
            height: auto;
          

            @media (max-width:1024px) {
                width: fit-content;
                padding: 2rem;
                
                
            }
            @media (max-width:767px) {
                
                padding: 1.5rem;
                
            }

            h1{
                
                padding-top: 200px;

                @media (max-width:2000px) {
                    padding-top: 200px;
                }
                @media (max-width:1500px) {
                    padding-top: 20px;
                } 
            }
        }

        .title{
            padding-top: 20px;
            width:100%;
            font-size: 4rem;
            line-height: 5rem;
            font-weight: 200;
            margin-bottom: 1rem;
            text-transform: uppercase;
            text-align: center;
            justify-content: center;

            & span{
                font-weight: 800;
            }
            
            @media (max-width:1024px) {
                
                width: 100%;
                margin-top: 1rem;
                text-align: center;
                line-height: 4rem;
                
            }
            @media (max-width:767px) {
                
                width: 100%;
                font-size: 2.5rem;
                
            }
        }
        & p{
            width: 100%;
            padding: 4px;
            padding-top: 30px;
            font-size: 20px;
            
            color: var(--font-color);
            text-align: center;
            justify-content: center;
            line-height: 40px;

            @media (max-width:1024px) {
                
                width: 100%;
                text-align: center;
                
            }
        }

        /* .sub_banner_image{
        
            border-radius: 0 3rem;
            

            

           
        } */

        /* .banner_stlye_1{
            display: flex;
            align-items: center;
            box-shadow: 0 1rem 3rem rgb(0 0 0/12%);
            background-color: var(--bg-dark-color);
            position: absolute;
            right: 32px;
            bottom: 28px;
            padding: 10px 10px;
            border-radius: 8px;
            width: 150px;

            & h4{
                margin: 0;
                font-size: 12px;
            }

            & svg{
                line-height: 40px;
                margin-right: 15px;
                text-align: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;

                & path{
                    fill: var(--green-color);
                }

            }

            @media (max-width:1024px) {
               
                display: none;
                
            }
            
           

        }

        .banner_style_2{
            right: 0;
            left: 0;
            bottom: 100px;
            width: 150px;

            & svg{
                & path{
                    fill: var(--primary-color);

                }
                
            }
            @media (max-width:1024px) {
              
                display: none;
                
            }

          
            


        }

        .banner_style_3{
            right:0;
            left:30px;
            bottom:266px;
            top:26px;
            width: 140px;


            & svg{
                & path{
                    fill: var(--blue-color);

                }
                
            }
            @media (max-width:1024px) {
            
                display: none;
                
            }

          
        } */

        .breached_btn{
            padding-bottom: 40px;
            padding-top: 30px;
        text-align: center;
        margin: auto;
        justify-content: center;

        }
    }
}

/*Home Cover Animation*/
@keyframes wave {
    0%,
    100%{
        transform: translate(0px, 0px);
    }
    50%{
        transform: translate(-10px, -10px);
    }
}