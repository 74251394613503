/*Blog item CSS*/

.blog_container{
    


  
    padding-right: 5rem;
    padding-left: 5rem;
    

    

    
  
    
  }

.blogItem_wrapper{
    display:flex;
    flex-direction: column;
    margin-left: -40px;
    margin-right: -40px;
    
    

}

.blogItem_cover{

    width: 100%;
    
    height: 250px;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: .5rem;
    cursor: pointer;


    /* @media (max-width: 1500px) {
        width: 20%;
        
    } */

    &:hover{

       opacity: 0.7;
        
        }

    
}

.blogItem_wrapper h3{
    margin: .5rem 0 1rem 0;
    flex: 1; 
    cursor: pointer;

    &:hover{

        text-decoration: underline;
         
         }

}

.blogItem_description{
    position:relative;
    max-height: 60px;
    overflow: hidden;
    font-size: .8rem;
    padding-right: .6rem;
    text-align: left;
    justify-content: left;
    color: #a9a9a9;

    

}




.blogItem_description::before{
    position: absolute;
    content: '...';
    bottom: 0;
    right: 0;
}


.blogItem_wrapper footer{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: space-between;
}

.blogItem_link{
    text-decoration: none;
    color: #ffffff;
}

.blogItem_author{
    display: flex;
    align-items: center;
}




.blogItem_author img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: .5rem;

    

    

}

.blogItem_author p{
    font-size: .6rem;
    color: #a9a9a9;
    font-weight: 600;

   
    
}

