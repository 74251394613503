/*Services CSS*/



#services{
    padding: 5rem 0 3rem;
    background: url('../../assets/banner/banner09.jpg') no-repeat center center;
    background-size: cover;
    position: relative;


    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: 100%;
        z-index: 1;
        background: var(--dark-layer-color);
        pointer-events: none;

    }

    @media (max-width:992px) {
        padding: 3rem 0;
        
    }

    .title_headling{
        text-align: center;
        max-width: 50rem;
        margin: auto;
        position: relative;
        z-index: 2;

        & h3{
            width: 90%;
            font-size: 2.5rem;
            line-height: 3.214rem;
            font-weight: 700;
            color: var(--white-color);
            margin-bottom: 0;

            @media (max-width:767px) {
                width: 100%;
                font-size: 1.8rem;
                line-height: 2.8rem;
                
            }
        }

        & p{
            width: 90%;
            font-size: 1.214rem;
            line-height: 2rem;
            color: var(--font-color);

            @media (max-width:767px) {
                width: 100%;
                
            }

        }
    }


    .services_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 5rem;

        @media (max-width:767px) {
            flex-direction: column;
            
        }

        .service_box{
            width: calc(100% / 3 - 2rem);
            padding: 2.8571rem 2.1429rem;
            border-radius: 1.4286rem;
            background: var(--bg-dark-color);
            box-sizing: border-box;
            margin-bottom: 2rem;
            position: relative;
            z-index: 3;

            @media (max-width:992px) {
                width: calc(100% / 2 - 1rem);
                
            }

            @media (max-width:767px) {
                width: 100%;
                
            }

            .number{
                position: absolute;
                font-size: 4.2857rem;
                line-height: 4.2857rem;
                right: 2.8571rem;
                top: 2.1429rem;
                opacity: .07;
                margin: 0;
                transition: 0.3s ease-in-out;
            }

            .service_icon{
                font-size: 2.2857rem;
                width: 5rem;
                height: 5rem;
                line-height: 5rem;
                border-radius: 50%;
                color: var(--primary-color);
                background: var(--primary-bg);
                text-align: center;

                &.green_icon{
                    color: var(--green-color);
                    background: var(--green-color-bg);
                }

                &.blue_icon{
                    color: var(--blue-color);
                    background: var(--blue-color-bg);
                }
            }

            & h5{
                font-weight: 600;
                color: var(--white-color);
                font-size: 1.7143rem;
                line-height: 2.4286rem;
            }

            & p{
                font-size: 1.2143rem;
                line-height: 2rem;
                color: var(--font-color);
                font-weight: 500;

            }

            .read{
                font-size: 1rem;
                font-weight: 600;
                color: var(--white-color);
                margin-top: 2.1429rem;
                display: inline;
                text-transform: capitalize;
            }
        }
        .btn_wrapper{
            margin-top: 2rem;
            z-index: 999;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            display: block;
            margin: 0 auto;

            .btn{
                text-align: center;

            }

            
        }
        
    }

}