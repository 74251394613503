/*Reach Out To Us CSS*/

#reach{
    padding: 6rem 0 4rem;
    background-color: aliceblue;
    padding: 35px;
    opacity: 100%;
    

    .reach_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 992px) {
            flex-direction: column-reverse;
            
        }

        .reach_col{

            height: auto;
            position: relative;
            z-index: 1;


            @media (max-width: 992px) {
                width: fit-content;
                padding: 2rem;
                
            }

            @media (max-width: 767px) {
                padding: 1.5rem;
                
            }
        }

        .reach_btn_wrapper{
            margin-top: 3rem;
            text-align: center;
          
            @media (max-width: 992px){
              width: 100%;
              text-align: center;
              display: block;

            }
          
            @media (max-width: 767px){
              margin-bottom: 3rem;
              text-align: center;
              display: block;
              
            }
            @media (max-width: 500px){
                display: flex;
                flex: auto;

                
              }

            @media (max-width: 400px){
                display: flex;
                flex: auto;

                
              }
              @media (max-width: 320px){
                display: flex;
                flex: auto;
                
              }
          
            .reach_btn{
              padding: 0.857rem 2.571rem;
              font-size: 1.143rem;
              border-radius: 2.143rem;
              font-weight: 600;
              outline: 2px solid var(--primary-color);
              outline-offset: 0px;
              text-align: center;
              border: none;
              cursor: pointer;
              color: var(--white-color);
              background: var(--primary-color);
              transition: all .3s ease-in-out;
          
              
          
              &:hover{
                outline-offset: 0.7143rem;
                
              }
            }
          }

        & h3{
            width: 100%;
            font-size: 2.1rem;
            color: black;
            line-height: 3.714rem;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            

            @media (max-width: 992px) {
                width: 100%;
                text-align: center;
                
            }

            @media (max-width: 767px) {
                width: 100%;
                font-size: 2.2rem;
                line-height: 3.5rem;
                
            }
        }

        & p{
            width: 80%;
            margin: 0;
            padding: 0;
            font-size: 1.143rem;
            line-height: 2rem;
            color: var(--font-color);

            @media (max-width: 992px) {
                width: 100%;
                text-align: center;
                
            }
        }

        

        
    }
}