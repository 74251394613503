/*Privacy CSS*/

.privacy_div{
    background-color: #ffffff;
    text-align: left;
    color: black;

    .black_class{
        color: black;
        text-align: left;
        padding-left: 40px;
        /* font-size: 100%; */
        
    }

    .black_class_gg{
        color: black;
        text-align: left;
        padding-left: 40px;
        font-size: 100%;
        line-height: 0%;
    }
}
