/*Contact CSS*/

#contact_details{
    padding: 3rem 0;
    background-color: #ffffff;

    

    .contact_wrapper{
        display: flex;
        justify-content: space-between;
        margin-top: 4rem;
        flex-wrap: wrap;

        @media (max-width:992px) {
            justify-content: center;

            
        }

        @media (max-width:767px) {
            flex-direction: column;
            
            
        }

        .contactdetails_col{
            width: calc(100% / 2 - 1rem);
            padding: 1rem;
            box-sizing: border-box;

            h2{
                color: #000000;
            }

            @media (max-width:992px) {
                width: 50%;
                
                
            }

            @media (max-width:767px) {
                width: 100%;
                
                
            }
        }

        .inputdetails_wrapper{
            width: 100%;
            display: flex;

            p{
                .embark{
                    color: black;
                }
                color: black;
            }

            .form-control{
                width: 100%;
                background-color: transparent;
                /* border-color: .0714rem solid var(-white-color); */
                border-color: #000000;
                color: var(-white-color);
                font-size: 1.0714rem;
                font-weight: 400;
                padding: .7143rem 1.4286rem;
                border-radius: .5714rem;
                margin-bottom: 3rem;
                outline: none;
                border-width: 1.4px;
            }

            

            & textarea{
                width: 100%;
                display: block;
                background-color: transparent;
                /* border-color: .0714rem solid var(-white-color); */
                border-color: #000000;
                color: var(-white-color);
                font-size: 1.0714rem;
                font-weight: 400;
                padding: .7143rem 1.4286rem;
                border-radius: .5714rem;
                min-height: 7.1429rem;
                outline: none;
                border-width: 1.4px;

            }

            
            

            /* .form-control:hover,
            & textarea:hover,
            .form-control:focus,
            & textarea:focus{
                outline-color: transparent;
  outline-style: none;
            } */
        }

        .btn_wrapper{
            margin-top: 2rem;
        }

        .contactdetails_image{
            position: relative;
            background-color: #f8f8f8;
            box-shadow: #000000;
            color: #000000;
            text-align: center;
            padding: 20px;

            

            h3{
                color: #000000;
                text-align: center;
                margin-bottom: 20px;
              
                
            }

            a{

                font-size: 25px;
                padding: 20px;

                
            }

            a:hover{

                color: #000000;
                
            }

            p{
                text-align: center;
                margin-left: 10px;
                margin-right: 10px;
                color: #000000;

            }

            span{
              
                color: #000000;
                font-size: 16px;
            }

            h6{
                color: #000000;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                margin-top: 10px;
                margin-left: 10px;

                
            }
            
            

            @media (max-width:1024px) {
                &::after{
                    right: 0;
                }
                
                
            }

            & img{
                border-radius: 2.1429rem;
                position: relative;
                z-index: 2;
                max-width: 85%;
            }
        }
    }
}

