/*Our Blog*/
.blog-contain{
  background-color: #fff;
}


.blog-wrap {
    max-width: 50%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    
    
    
    @media (max-width:400px) {
      padding-left: 0;
    padding-right: 0;
   
      
    }
    

    
    
  }
  
  
  
  
  .blog-goBack {
    text-decoration: none;
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
    margin-bottom: 2rem;
    display: block;
    
  }
  .blog-wrap header h1 {
    text-align: center;
    font-weight: 510;
    color: black;
    font-size: 40px;
    
    @media (max-width:850px) {
      font-size: 35px;
      
  }
  @media (max-width:680px) {
 
    font-size: 30px;
    
}

@media (max-width:500px) {
  font-size: 25px;
  
}
@media (max-width:400px) {
  padding-left: 0;
  padding-right: 0;
  font-size: 20px;
  
}
@media (max-width:300px) {
  padding-left: 0;
  padding-right: 0;
  font-size: 15px;
  
}

    
  }
  
  .blog-date {
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight:500;
    
  }
  
  .blog-wrap img {
    width: 80%;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 20px;
    object-fit: cover;

    @media (max-width:800px) {
      width: 100%;
      
    }
   

    /* @media (max-width:802px) {
      text-align: center;
      
  } */
    
  }
  
  .blog-subCategory {
    display: flex;
    justify-content: center;
  }
  .blog-subCategory > div {
    margin: 1rem;
  }
  .blog-desc {
    padding: 1rem;
    margin-top: 1.5rem;
  }
  


.blog_description p{
  color: black;
  @media (max-width:1000px) {
    text-align: center;
    
}
@media (max-width:900px) {
  font-size: 16px;
  
}
@media (max-width:800px) {
  padding-left: 0;
padding-right: 0;
  font-size: 15px;
  
  
}

@media (max-width:700px) {
  padding-left: 0;
padding-right: 0;
  font-size: 14px;
  
}

@media (max-width:600px) {
  padding-left: 0;
padding-right: 0;
  font-size: 13px;
  
}
@media (max-width:500px) {
  padding-left: 00;
padding-right: 00;
  font-size: 12px;
  
}
@media (max-width:400px) {
  padding-left: 0;
padding-right: 0;
width: 100%;
  font-size: 11px;
  line-height: 20px;
  
}
@media (max-width:300px) {
  width: fit-content;
  justify-content: center;
  text-align: center;
padding-left: 0%;
padding-right: 0%;
margin: 0%;
  font-size: 9px;
  
}
}

.fb_icon{
  @media (max-width:300px) {
    display: none;
    
  }
}
  