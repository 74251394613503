/*All Services CSS*/

#allservices_container{
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 84px);
    padding: 8rem 0 0;

    @media (max-width: 767px) {
        padding: 3rem 0 0;
        
    }

    


    .service_banner{
        position: absolute;
        overflow: hidden;
        background: url('../../assets/service_about/allservice5.jpg') no-repeat center center;
        background-size: cover;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 84px);
        opacity: 50%;
        transform: scale(1.1);
        animation: wave 20s linear infinite;

        &:before{
            content: '';
            position: absolute;
            top: 0;
            min-height: 100%;
            left: 0;
            right: 0;
            z-index: 2;
            background: var(--dark-layer-color);
        }

    }

    


    .service_outer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 3;

        @media (max-width:992px) {
            flex-direction: column-reverse;
        }

        
        

        .service_title{
            width:100%;
            padding: 80px;
            font-size: 4rem;
            line-height: 5rem;
            font-weight: 200;
            margin-bottom: 1rem;
            text-transform: uppercase;
            text-align: center;
            justify-content: center;

            & span{
                font-weight: 800;
            }
            @media (max-width:2000px) {
                padding-top: 250px;
            }
            @media (max-width:1500px) {
                padding-top: 120px;
            } 
            @media (max-width:992px) {
                
                width: 100%;
                margin-top: 10rem;
                text-align: center;
                line-height: 4rem;
                
            }
            @media (max-width:767px) {
                
                width: 100%;
                font-size: 2.5rem;
                
            }
        }
    }

    
    

    

}

hr{
    opacity: 30%;
    margin-top: 20px;

}

#serviceAllCAS_container{
    .serviceAllCAS_wrapper{
        .serviceAllCAS_col{
            h3{
                padding-top: 20px;
                text-align: center;

                @media (max-width: 600px){
                    font-size: 24px;
                }
                @media (max-width: 500px){
                    font-size: 22px;
                 }
                @media (max-width: 400px){
                    font-size: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
                @media (max-width: 300px){
                padding-left: 0px;
                padding-right: 0px;
                font-size: 18px;
                }
                @media (max-width: 280px){
                padding-left: 0px;
                padding-right: 0px;
                font-size: 18px;
                display: flex;
                margin: auto;
                justify-content: center;
                }

       
          
            @media (max-width: 767px){
              margin-bottom: 2rem;
              
            }
            }
            p{
                width: 100%;

                @media (max-width: 600px){
                          font-size: 14px;
                }
                @media (max-width: 500px){
                    font-size: 13px;
          }
          @media (max-width: 400px){
            font-size: 11px;
            padding-left: 0px;
    padding-right: 0px;
  }
  @media (max-width: 300px){
    padding-left: 0px;
    padding-right: 0px;
    font-size: 9px;
}
@media (max-width: 250px){
    padding-left: 0px;
    padding-right: 0px;
    font-size: 9px;
}

             
                
                  @media (max-width: 767px){
                    margin-bottom: 2rem;
                    
                  }
            }



            .serviceall_cyber_wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 4rem;
                flex-wrap: wrap;
        
                @media (max-width:992px) {
                    justify-content: center;
                    
                }
                @media (max-width:992px) {
                    flex-direction: column;
                    
                }
        
                .serviceall_cyber_box{
                    width: calc(100% / 3 - 1rem);
                    padding: 1rem;
                    box-sizing: border-box;
                    text-align: center;
        
                    @media (max-width:992px) {
                        width: 50%;
                        
                    }
        
                    @media (max-width:767px) {
                        width: 100%;
                    }
        
                    .serviceall_cyber_icon{
                        font-size: 2.2857rem;
                        width: 5.7143rem;
                        height: 5.7143rem;
                        margin: auto;
                        line-height: 5.7143rem;
                        border-radius: 50%;
                        color: var(--primary-color);
                        background-color: var(--primary-bg);
                        text-align: center;


                        
        
                        &.green_icon{
                            color: var(--green-color);
                            background: var(--green-color-bg);

                            
                            
                        }
        
                        &.blue_icon{
                            color: var(--blue-color);
                            background: var(--blue-color-bg);

                            
                        }
        
                    }
        
                  
        
                    & h5{
                        font-weight: 300;
                        color: var(--white-color);
                        font-size: 1.7143rem;
                        margin: 1.67rem 0;
                        line-height: 2.4268rem;

                       
                      
                      @media (max-width: 380px){
                          
                          font-size: 1.2rem;

            
                      
                          
                        }
                        @media (max-width: 330px){
                    
                            font-size: 1.1rem;
                          
                        }
                        @media (max-width: 300px){
                    
                            font-size: 1.0rem;
                          
                        }
                        @media (max-width: 280px){
                    
                            font-size: 0.8rem;
                          
                        }
                        @media (max-width: 250px){
                    
                            font-size: 0.8rem;
                            text-align: center;
                            display: flex;
                          
                        }
                   
                      
                        @media (max-width: 767px){
                          margin-bottom: 2rem;
                          
                        }
                    }
        
                    & p{
                        font-size: 1.2143rem;
                        line-height: 2rem;
                        color: var(--font-color);
                        font-weight: 500;
        
                        @media (max-width:350px) {
                            display: none;
                            padding: 0%;
                            font-size: 12px;
                            line-height: 2.8rem;
                            
                        }
                    }

                    .serviceall_cyber_btn{
                        padding: 0.857rem 2.571rem;
                        font-size: 0.9rem;
                        border-radius: 2.0rem;
                        font-weight: 600;
                        /* box-shadow: 0 0.5rem 1rem rgb(16,16,16); */
                        outline: 2px solid var(--primary-color);
                        outline-offset: 0px;
                        border: none;
                        cursor: pointer;
                        color: var(--white-color);
                        /* background: var(--primary-color); */
                        transition: all .3s ease-in-out;
                        justify-content: center;
                    
                        
                    
                        &:hover{
                          outline-offset: 0.7143rem;
                          
                        }

                 
                        
                          @media (max-width: 500px){
                            
                         
                           
                        
                            
                          }
                        
                        @media (max-width: 400px){
                            display: flex;
                            flex: auto;
                            margin: auto;
                            text-align: center;
              
                        
                            
                          }
                          @media (max-width: 320px){
                      
                            display: flex;
                            flex: auto;
                            margin: auto;
                            text-align: center;
                            font-size: 9px;
                            outline: 1px solid var(--primary-color);
                            border-radius: 1.5rem;
                            
                          }
                     
                        
                          @media (max-width: 767px){
                            margin-bottom: 2rem;
                            
                          }
                      }
                }
            }
        }
    }
}



#serviceAllCS_container{
    .serviceAllCS_wrapper{
        .serviceAllCS_col{
            h3{
                padding-top: 40px;
                text-align: center;
              

                @media (max-width: 600px){
                    font-size: 24px;
                }
                @media (max-width: 500px){
                    font-size: 22px;
                 }
                @media (max-width: 400px){
                    font-size: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
                @media (max-width: 300px){
                padding-left: 0px;
                padding-right: 0px;
                font-size: 18px;
                }
                @media (max-width: 280px){
                padding-left: 0px;
                padding-right: 0px;
                font-size: 18px;
                display: flex;
                margin: auto;
                justify-content: center;
                }

       
          
            @media (max-width: 767px){
              margin-bottom: 2rem;
              
            }
            }
            p{
                width: 100%;

                @media (max-width: 600px){
                          font-size: 14px;
                }
                @media (max-width: 500px){
                    font-size: 13px;
          }
          @media (max-width: 400px){
            font-size: 11px;
            padding-left: 0px;
    padding-right: 0px;
  }
  @media (max-width: 300px){
    padding-left: 0px;
    padding-right: 0px;
    font-size: 9px;
}
@media (max-width: 250px){
    padding-left: 0px;
    padding-right: 0px;
    font-size: 9px;
}

             
                
                  @media (max-width: 767px){
                    margin-bottom: 2rem;
                    
                  }
            }


            .serviceall_ict_wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 4rem;
                flex-wrap: wrap;
        
                @media (max-width:992px) {
                    justify-content: center;
                    
                }
                @media (max-width:992px) {
                    flex-direction: column;
                    
                }
        
                .serviceall_ict_box{
                    width: calc(100% / 3 - 1rem);
                    padding: 0.2rem;
                    box-sizing: border-box;
                    text-align: center;
                   
        
                    @media (max-width:992px) {
                        width: 50%;
                        
                    }
        
                    @media (max-width:767px) {
                        width: 100%;
                    }
        
                    .serviceall_ict_icon{
                        font-size: 2.2857rem;
                        width: 5.7143rem;
                        height: 5.7143rem;
                        margin: auto;
                        line-height: 5.7143rem;
                        border-radius: 50%;
                        color: var(--primary-color);
                        background-color: var(--primary-bg);
                        text-align: center;



                        
        
                        &.green_icon{
                            color: var(--green-color);
                            background: var(--green-color-bg);

                            
                            
                        }
        
                        &.blue_icon{
                            color: var(--blue-color);
                            background: var(--blue-color-bg);

                            
                        }
        
                    }
        
                  
        
                    & h5{
                        font-weight: 300;
                        color: var(--white-color);
                        font-size: 1.7143rem;
                        margin: 1.67rem 0;
                        line-height: 2.4268rem;

                       
                      
                      @media (max-width: 380px){
                          
                          font-size: 1.2rem;

            
                      
                          
                        }
                        @media (max-width: 330px){
                    
                            font-size: 1.1rem;
                          
                        }
                        @media (max-width: 300px){
                    
                            font-size: 1.0rem;
                          
                        }
                        @media (max-width: 280px){
                    
                            font-size: 0.8rem;
                          
                        }
                        @media (max-width: 250px){
                    
                            font-size: 0.8rem;
                            text-align: center;
                            display: flex;
                          
                        }
                   
                      
                        @media (max-width: 767px){
                          margin-bottom: 2rem;
                          
                        }
                    }
        
                    & p{
                        font-size: 1.2143rem;
                        line-height: 2rem;
                        color: var(--font-color);
                        font-weight: 500;
        
                        @media (max-width:350px) {
                            display: none;
                            padding: 0%;
                            font-size: 12px;
                            line-height: 2.8rem;
                            
                        }
                    }

                    .serviceall_ict_btn{
                        padding: 0.857rem 2.571rem;
                        font-size: 0.9rem;
                        border-radius: 2.0rem;
                        font-weight: 600;
                        /* box-shadow: 0 0.5rem 1rem rgb(16,16,16); */
                        outline: 2px solid var(--primary-color);
                        outline-offset: 0px;
                        border: none;
                        cursor: pointer;
                        color: var(--white-color);
                        /* background: var(--primary-color); */
                        transition: all .3s ease-in-out;
                        justify-content: center;
                    
                        
                    
                        &:hover{
                          outline-offset: 0.7143rem;
                          
                        }

                 
                        
                          @media (max-width: 500px){
                            
                         
                           
                        
                            
                          }
                        
                        @media (max-width: 400px){
                            display: flex;
                            flex: auto;
                            margin: auto;
                            text-align: center;
              
                        
                            
                          }
                          @media (max-width: 320px){
                      
                            display: flex;
                            flex: auto;
                            margin: auto;
                            text-align: center;
                            font-size: 9px;
                            outline: 1px solid var(--primary-color);
                            border-radius: 1.5rem;
                            
                          }
                     
                        
                          @media (max-width: 767px){
                            margin-bottom: 2rem;
                            
                          }
                      }
                }
            }
        }
    }
}



#serviceAllCMS_container{
    .serviceAllCMS_wrapper{
        .serviceAllCMS_col{
            h3{
                padding-top: 40px;
                text-align: center;
              

                @media (max-width: 600px){
                    font-size: 24px;
                }
                @media (max-width: 500px){
                    font-size: 22px;
                 }
                @media (max-width: 400px){
                    font-size: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
                @media (max-width: 300px){
                padding-left: 0px;
                padding-right: 0px;
                font-size: 18px;
                }
                @media (max-width: 280px){
                padding-left: 0px;
                padding-right: 0px;
                font-size: 18px;
                display: flex;
                margin: auto;
                justify-content: center;
                }

       
          
            @media (max-width: 767px){
              margin-bottom: 2rem;
              
            }
            }
            p{
                width: 100%;

                @media (max-width: 600px){
                          font-size: 14px;
                }
                @media (max-width: 500px){
                    font-size: 13px;
          }
          @media (max-width: 400px){
            font-size: 11px;
            padding-left: 0px;
    padding-right: 0px;
  }
  @media (max-width: 300px){
    padding-left: 0px;
    padding-right: 0px;
    font-size: 9px;
}
@media (max-width: 250px){
    padding-left: 0px;
    padding-right: 0px;
    font-size: 9px;
}

             
                
                  @media (max-width: 767px){
                    margin-bottom: 2rem;
                    
                  }
            }


            .serviceall_di_wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 4rem;
                flex-wrap: wrap;
        
                @media (max-width:992px) {
                    justify-content: center;
                    
                }
                @media (max-width:992px) {
                    flex-direction: column;
                    
                }
        
                .serviceall_di_box{
                    width: calc(100% / 3 - 1rem);
                    padding: 0.2rem;
                    box-sizing: border-box;
                    text-align: center;
                   
        
                    @media (max-width:992px) {
                        width: 50%;
                        
                    }
        
                    @media (max-width:767px) {
                        width: 100%;
                    }
        
                    .serviceall_di_icon{
                        font-size: 2.2857rem;
                        width: 5.7143rem;
                        height: 5.7143rem;
                        margin: auto;
                        line-height: 5.7143rem;
                        border-radius: 50%;
                        color: var(--primary-color);
                        background-color: var(--primary-bg);
                        text-align: center;


                        
        
                        &.green_icon{
                            color: var(--green-color);
                            background: var(--green-color-bg);

                            
                            
                        }
        
                        &.blue_icon{
                            color: var(--blue-color);
                            background: var(--blue-color-bg);

                            
                        }
        
                    }
        
                  
        
                    & h5{
                        font-weight: 300;
                        color: var(--white-color);
                        font-size: 1.7143rem;
                        margin: 1.67rem 0;
                        line-height: 2.4268rem;

                       
                      
                      @media (max-width: 380px){
                          
                          font-size: 1.2rem;

            
                      
                          
                        }
                        @media (max-width: 330px){
                    
                            font-size: 1.1rem;
                          
                        }
                        @media (max-width: 300px){
                    
                            font-size: 1.0rem;
                          
                        }
                        @media (max-width: 280px){
                    
                            font-size: 0.8rem;
                          
                        }
                        @media (max-width: 250px){
                    
                            font-size: 0.8rem;
                            text-align: center;
                            display: flex;
                          
                        }
                   
                      
                        @media (max-width: 767px){
                          margin-bottom: 2rem;
                          
                        }
                    }
        
                    & p{
                        font-size: 1.2143rem;
                        line-height: 2rem;
                        color: var(--font-color);
                        font-weight: 500;
        
                        @media (max-width:350px) {
                            display: none;
                            padding: 0%;
                            font-size: 12px;
                            line-height: 2.8rem;
                            
                        }
                    }

                    .serviceall_di_btn{
                        padding: 0.857rem 2.571rem;
                        font-size: 0.9rem;
                        border-radius: 2.0rem;
                        font-weight: 600;
                        /* box-shadow: 0 0.5rem 1rem rgb(16,16,16); */
                        outline: 2px solid var(--primary-color);
                        outline-offset: 0px;
                        border: none;
                        cursor: pointer;
                        color: var(--white-color);
                        /* background: var(--primary-color); */
                        transition: all .3s ease-in-out;
                        justify-content: center;
                    
                        
                    
                        &:hover{
                          outline-offset: 0.7143rem;
                          
                        }

                 
                        
                          @media (max-width: 500px){
                            
                         
                           
                        
                            
                          }
                        
                        @media (max-width: 400px){
                            display: flex;
                            flex: auto;
                            margin: auto;
                            text-align: center;
              
                        
                            
                          }
                          @media (max-width: 320px){
                      
                            display: flex;
                            flex: auto;
                            margin: auto;
                            text-align: center;
                            font-size: 9px;
                            outline: 1px solid var(--primary-color);
                            border-radius: 1.5rem;
                            
                          }
                     
                        
                          @media (max-width: 767px){
                            margin-bottom: 2rem;
                            
                          }
                      }
                }
            }
        }
    }
}










