

.searchbar_wrapper{
    
    background-color: transparent;
    width: 400px;
    
    margin: 2.5rem auto 4rem auto;
    border-color: #990033;
    
    
    

    border-bottom: 1px solid #990033;
    border-top: transparent;
    border-left: transparent;
    border-bottom: transparent;
    -webkit-box-shadow: 0 1px 0 0 #990033;
    -moz-box-shadow: 0 1px 0 0 #990033;
    box-shadow: 0 4px 0 0 #990033;


    @media (max-width:400px) {
        width: 90%;
    }    
   
 

    
}

.searchbar_wrapper form{
    display: flex;
    align-items: center;
    width: 400px;
  
    @media (max-width:400px) {
        width: 90%;
    }   
    
    

}

.searchbar_wrapper input{
    
    background-color: transparent;
    border-color: #990033;
    border-radius: 10px;
    outline: none;
    border: none;
    width: 400px;
    color: #ffffff;
    margin-bottom: 12px;
    padding-left: 10px;

    @media (max-width:400px) {
        width: 90%;
        font-size: 10px;
        
    }   
 

}

.searchbar_wrapper span{
    padding-right: .5rem;

    cursor: pointer;
    
    

}


.searchbar_wrapper button{
    outline: none;
    border: none;
    text-align: right;
    padding: .3rem 1rem;
    border-radius: 5px;
    padding-right: 15px;
  
    background-color: transparent;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 12px;

    @media (max-width:400px) {
        width: 90%;
        font-size: 12px;
    }   
    

}


 /* body {
    background-image: linear-gradient(to right , #cb2d3e , #ef473a);
}

.searchBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:  translate(-50%,50%);
    background: #2f3640;
    height: 40px;
    border-radius: 40px;
    padding: 10px;

}

.searchBox:hover > .searchInput {
    width: 240px;
    padding: 0 6px;
}

.searchBox:hover > .searchButton {
  background: white;
  color : #2f3640;
}

.searchButton {
    color: white;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #2f3640;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.searchInput {
    border:none;
    background: none;
    outline:none;
    float:left;
    padding: 0;
    color: white;
    font-size: 16px;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;

}

@media screen and (max-width: 620px) {
.searchBox:hover > .searchInput {
    width: 150px;
    padding: 0 6px;
}
} */