/*Footer CSS*/


.footer{
    background-color: var(--dark-layer-color);
    padding: 2rem;
    text-align: center;
    border-top: .0714rem solid var(--border-color-light);

    & p{
        color: var(--white-color);
        text-align: center;

        @media (max-width:400px) {
            font-size: 13px;
            
        }
        @media (max-width:350px) {
            font-size: 11px;
            
        }
    }

    

}

/*Endss*/

/* .footer-container{
    background-color: #16171d;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
}

.footer-container:hover{
    background-color: #16171d;
}

.footer-subscription{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #ffffff;
}

.footer-subscription-heading{
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-heading:hover{
    margin-bottom: 24px;
    font-size: 24px;
    color: #fffb13;
}

.footer-subscription-text{
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input{
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #ffffff;
}

.footer-input:hover{
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid rgb(239, 255, 11);
    background-color:rgb(239, 255, 11);
}


.footer-links{
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper{
    display: flex;
}

.footer-btn{
    color: white;
    width: 100px;
    height: 40px;
    background-color: var(--primary-color);
    background-color: transparent;
    border: 1px solid white;
    transition: all 0.3s ease-out;
    font-size: 20px;
    text-decoration: none;
}

.footer-btn:hover{
    color: rgb(241, 245, 22);
    width: 100px;
    height: 40px;
    background-color: var(--primary-color);
    background-color: transparent;
    border: 1px solid rgb(201, 241, 22);
    transition: all 0.3s ease-out;
    font-size: 20px;
    text-decoration: none;
}

.footer-links-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 300px;
    box-sizing: border-box;
}

.footer-links-items h4{
    margin-bottom: 16px;
}

.footer-links-items > h4{
    color: #ffffff;
}

.footer-links-items a{
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 1rem;
}

.footer-links-items a:hover{
    color: var(--primary-color);
    transition: 0.3s ease-out;
}

.footer-email-form h2{
    margin-bottom: 2rem;
}

.footer-input::placeholder{
    color: #b1b1b1;
}

.social-icon-link{
    color: #ffffff;
    font-size: 24px;
}

.social-icon-link:hover{
    color: rgb(255, 240, 24);
    font-size: 24px;
}

.social-media{
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.social-icons:hover{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    color: rgb(255, 251, 6);
}

.social-logo{
    color: #ffffff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.social-logo:hover{
    color: rgb(239, 243, 9);
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.website-rights{
    color: #ffffff;
    margin-bottom: 16px;
}

.website-rights:hover{
    color: rgb(255, 231, 17);
    margin-bottom: 16px;
}

@media screen and (max-width: 820px){
    .footer-links{
        padding-top: 2rem;
    }

    .btn{
        width: 100%;
    }

    .footer-link-wrapper{
        flex-direction: column;
    }

    .social-media-wrap{
        flex-direction: column;
    }
} */

/*New Ends*/

/* .section_padding{
    padding: 2rem 2rem;
}

.footer{
    background-color: #2d2d32;
}

.sb_footer{
    display: flex;
    flex-direction: column;
}
.sb_footer-links{
    display: flex;
    justify-content: baseline;
    text-align: left;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}

.sb_footer-links-div{
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
}

a{
    color: rgb(175, 175, 179);
    text-decoration: none;
}

.socialmedia{
    display: flex;
    flex-direction: row;
}

.socialmedia img{
    width: 100%;
}

.sb_footer-links-div h4{
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}
.sb_footer-links-div p{
    font-size: 12px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;

}

.sb_footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb_footer-below-links{
    display: flex;
    flex-direction: row;
}

.sb_footer-below-links p{
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: white;
    font-weight: 600;

}

hr{
    color: white !important;
    width: 100%;
}

.sb_footer-copyright p{
    font-size: 13px;
    line-height: 15px;
    color: rgb(255, 255, 255);
    font-weight: 600;


} */


/*Scroll to Top CSS*/
.scroll_top{
    position: fixed;
    right: 30px;
    bottom: 100px;
    z-index: 99;
    cursor: pointer;
    width: 2.8571rem;
    height: 2.8571rem;
    line-height: 3.5rem;
    text-align: center;
    background-color: var(--primary-color);
    border-radius: 100%;

    & svg{
        text-align: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;

        & path{
            fill: var(--white-color);
        }

    }

}