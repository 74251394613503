/*Coverage CSS*/

#coverageSection{
    position: relative;
    overflow: hidden;
    height: 380px;
    background-color: #ffffff;   


    @media (max-width: 767px) {
        padding: 3rem 0 0;
        
    }


    @media (max-width: 420px) {
        display: none;
        
    }

    .coverage_banner{
        position: absolute;
        margin: auto;
        background: url('../../assets/contact/CoverageMap11.png') no-repeat center center;
        background-size: cover;
        text-align: center;
        z-index: 1;
        width: 120%;
        height: 120%;
        left: 10px;
        overflow: hidden;
        padding-bottom: 50px;
        transform: scale(1.1);
        animation: wave 20s linear infinite;

        
        

        &:before{
            content: '';
            position: absolute;
            top: 0;
            min-height: 100%;
            left: 0;
            right: 0;
            z-index: 2;
     
        }

    }


    .coverage_outer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 3;

        @media (max-width:992px) {
            flex-direction: column-reverse;
        }

        


        

        .coverage_title{
            width:100%;
            font-size: 4rem;
            line-height: 5rem;
            font-weight: 200;
            margin-bottom: 1rem;
            text-transform: uppercase;
            text-align: center;

            & span{
                font-weight: 800;
            }
            @media (max-width:992px) {
                
                width: 100%;
                margin-top: 10rem;
                text-align: center;
                line-height: 4rem;
                
            }
            @media (max-width:767px) {
                
                width: 100%;
                font-size: 2.5rem;
                
            }
        }
    }

}