.left{
    text-align: left;

    @media (max-width:1000px) {
        
        text-align: center;
        
      }
      @media (max-width:400px) {
        
        font-size: 16px;
        
      }
      @media (max-width:300px) {
        
        font-size: 14px;
        
      }
}

h4{
  color: black;
  padding-top: 40px;

  @media (max-width:400px) {
    font-size: 18px;
    
  }
}

.data_subTitle{
  h3{
    color: black;
    text-align: center;
  }
  color: black;
  padding-top: 40px;

  @media (max-width:500px) {
    width: 100%;
    text-align: center;
    
    
  }

  @media (max-width:400px) {
    font-size: 20px;

    
  }
}

    
