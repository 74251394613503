.container{
    &.slide_back{
        background-color: #171717;
        padding-top: 50px;


        @media (max-width:356px) {
            display: none;   
        }
        
    }
}


    h2{
        text-align: center;
        padding-bottom: 20px;
    }

    .partner_slider_col{
        width: 500px;
        align-items: center;
        text-align: center;
        margin-left: 25px;
        padding-bottom: 30px;

    }

    
