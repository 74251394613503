/*Page Not Found CSS*/

#pagenotfound{
    .pagenotfound_Icon{
        color: aqua;
        text-align: center;
        padding: 60px;
        
        img{
            text-align: center;
            width: 150px;
            height: 150px;
            margin-top: 20px;
        }

    }

}